import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Distance } from '../../primitive/Distance/Distance'
import { ContainerAccordion } from '../ContainerAccordion/ContainerAccordion'
import styles from './ResultDetail.module.scss'
import { getCurrentPosition } from '../../../utils/geolocation'
import { ResultWrapper } from '../../block/ResultWrapper/ResultWrapper'
import { ConfigProps, StationObjProps } from '../../page/Map'
import { MapType } from '../../../type/MapType'
import { StationProps } from '../../../type/StationProps'
import { TabExtProps, Tabs } from '../Tabs/Tabs'
import { getLayoutIconBasedOnMapType } from '../../../utils/getLayoutIconBasedOnMapType'
import { formatPragueDistricts } from '../../../utils/formatPragueDistricts'
import { BulkyType } from '../../../type/BulkyType'
import { Updater } from 'use-immer'
import text from '../../../text.json'

interface ResultDetailProps {
    station: StationProps | undefined
    setStation: (station: StationObjProps['activeStation']) => void
    setStationEval: React.Dispatch<React.SetStateAction<string | null>>
    mapType: MapType
    map?: google.maps.Map
    updateConfig: Updater<ConfigProps>
    className?: string
}

// create a function for times
const formatTime = (time: string | undefined) => {
    if (!time) return ''
    const timeObj = new Date(`1970-01-01T${time}`)
    const hours = timeObj.getHours()
    const minutes = timeObj.getMinutes()
    const minutesString = minutes < 10 ? `0${minutes}` : minutes
    return `${hours}:${minutesString}`
}

const reUsePointsIds = [
    'CZA00547',
    'CZA00392',
    'CZA00230',
    'CZA01030',
    'CZA00188',
    'CZA00955',
    'CZA00767',
    'CZA01674',
]

export const ResultDetail = ({
    className,
    setStation,
    station,
    setStationEval,
    mapType,
    map,
    updateConfig,
}: ResultDetailProps) => {
    const [curPosition, setCurPosition] = useState<
        undefined | google.maps.LatLngLiteral
    >(undefined)
    const isReUsePoint = reUsePointsIds.includes(
        String(station?.properties?.icz)
    )
    const [showAlert, setShowAlert] = useState(true)

    const currentText = text.bulkyTypes.filter(
        (bulky) => bulky.id === station?.properties.bulkyType
    )[0]

    let evaluationTitle = 'Ohodnotit stanoviště'

    if (mapType === 'bulky-waste') {
        evaluationTitle = 'Zpětná vazba k bezplatné službě VOK'

        if (station?.properties.bulkyType === BulkyType.mixed) {
            evaluationTitle = 'Zpětná vazba k bezplatné službě BIO VOK'
        }
    }

    if (mapType === 'waste-collection') {
        evaluationTitle = 'Zpětná vazba pro sběrný dvůr HMP'
    }

    useEffect(() => {
        getCurrentPosition({
            onSuccess: ({ coords: { latitude: lat, longitude: lng } }) => {
                setCurPosition({ lat, lng })
            },
            onError: (e) => {
                console.log(`Error ${e.code}: ${e.message}`)
                setCurPosition(undefined)
            },
        })
    }, [])

    let headerTitle = 'Tříděný odpad'
    let headerText = station?.properties.name

    if (mapType === 'waste-collection') {
        headerTitle = station?.properties.name ?? ''
        headerText = station?.properties.address?.address_formatted ?? ''
    }

    if (mapType === 'bulky-waste') {
        headerTitle = 'Velkoobjemový kontejner'
        headerText = `${station?.properties.street ?? ''} - ${
            station?.properties.cityDistrict
                ? formatPragueDistricts(station?.properties.cityDistrict)
                : ''
        }`
    }

    const reUsePoint = {
        id: 're-use-point',
        label: 'Zde je možné využít Reuse point',
        iconName: 'reuse',
        text: String(station?.properties.address?.address_formatted),
    }

    const descTab: TabExtProps = {
        id: 'desc',
        label: 'Popis',
        iconName: 'desc',
        items: [
            {
                id: 'operating-hours',
                label: 'Otevírací doba',
                iconName: 'clock',
                text: station?.properties.operating_hours ?? '',
            },
            {
                id: 'district',
                label: 'Městská část',
                iconName: 'buildings',
                text: station?.properties.district
                    ? formatPragueDistricts(station?.properties.district)
                    : '',
            },
            ...(isReUsePoint ? [reUsePoint] : []),
            {
                id: 'operator',
                label: 'Provozovatel',
                iconName: 'card',
                text: station?.properties.operator ?? '',
            },
            {
                id: 'contact',
                label: 'Kontakt',
                iconName: 'phone',
                text: station?.properties.contact ?? '',
            },
        ],
    }

    const serviceTab: TabExtProps = {
        id: 'services',
        label: 'Služby',
        iconName: 'paper',
        items:
            station?.properties.properties?.map((property) => ({
                id: property.id,
                label: property.description,
                iconName: 'desc',
                text: property.value,
            })) ?? [],
    }

    const hasOccupancyId = !!station?.properties.icz

    const occupancyTab: TabExtProps = {
        id: 'occupancy',
        label: 'Návštěvnost',
        iconName: 'occupancy',
        occupancyId: station?.properties.icz,
    }

    const tabs = [
        descTab,
        serviceTab,
        ...(hasOccupancyId ? [occupancyTab] : []),
    ]

    let iconName = getLayoutIconBasedOnMapType(mapType)

    if (station?.properties.is_monitored) {
        iconName = `${iconName}-smart`
    }

    if (
        mapType === 'bulky-waste' &&
        station?.properties.bulkyType !== BulkyType.default
    ) {
        iconName = `${iconName}-bio`
    }

    const closeDetail = () => {
        setStation(undefined)
        updateConfig((draft) => {
            draft.filterOpen = false
            draft.resultListOpen = true
        })
    }

    return (
        <ResultWrapper
            open={station !== undefined}
            setOpen={closeDetail}
            openAnimated={true}
            backLabel={'Zpět na výsledky'}
            className={className}
        >
            <>
                {station?.activeStation && showAlert && (
                    <div className={styles.alert}>
                        <div className={styles.alertGroup}>
                            <div className={styles.alertTitle}>
                                Aktuální umístění
                            </div>
                            <div className={styles.alertText}>
                                Kontejner je aktuálně přistaven
                            </div>
                        </div>
                        <button
                            className={styles.alertButton}
                            onClick={() => {
                                setShowAlert(false)
                                closeDetail()
                                if (!station?.activeStation || !map) return
                                map.setCenter({
                                    lat: station.activeStation.geometry
                                        .coordinates[1],
                                    lng: station.activeStation.geometry
                                        .coordinates[0],
                                })
                                map.setZoom(24)
                            }}
                        >
                            Zobrazit aktuální umístění VOK
                        </button>
                    </div>
                )}
                <div className={styles.header}>
                    <div className={styles.headerRow}>
                        <div className={styles.headerCol}>
                            <img src={`/img/layout/${iconName}.svg`} alt="" />
                        </div>
                        <div className={styles.headerCol}>
                            <div className={styles.headerTitle}>
                                {headerTitle}
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => setStationEval('define-containers')}
                        className={clsx(styles.headerRow, styles.headerRowLink)}
                    >
                        <div className={styles.headerCol}>
                            <img src="/img/icon/heart.svg" alt="" />
                        </div>
                        <div className={styles.headerCol}>
                            <div className={styles.headerTitle}>
                                {evaluationTitle}
                            </div>
                        </div>
                        <img
                            className={styles.headerRowLinkArrow}
                            src="/img/layout/front-arrow.svg"
                            alt=""
                        />
                    </div>
                    <div className={styles.headerRow}>
                        <div className={styles.headerCol}>
                            <img src="/img/layout/map.svg" alt="" />
                        </div>
                        <div className={styles.headerCol}>
                            <div className={styles.headerText}>
                                {headerText}
                            </div>
                            {station && (
                                <Distance
                                    className={styles.headerDistance}
                                    content={{ distance: station.distance }}
                                />
                            )}
                        </div>
                    </div>
                    {curPosition ? (
                        <a
                            href={`https://www.google.com/maps/dir/?api=1&travelmode=walk&layer=traffic&destination=${station?.geometry.coordinates[1]},${station?.geometry.coordinates[0]}&origin=${curPosition.lat},${curPosition.lng}`}
                            rel={'noreferrer'}
                            target={'_blank'}
                            className={styles.headerBtn}
                        >
                            <img src="/img/layout/location.svg" alt="" />
                            Navigovat
                        </a>
                    ) : (
                        <span
                            className={styles.headerBtn}
                            onClick={() => {
                                window.alert(
                                    'Přístup k poloze není povolen. Pro zobrazení Vaší lokace povolte sdílení a aktualizujte stránku.'
                                )
                            }}
                        >
                            Načítám trasu...
                        </span>
                    )}
                </div>
                {currentText && (
                    <>
                        <div className={styles.header}>
                            <div className={styles.text}>
                                <div className={styles.title}>Patří sem</div>
                                <div className={styles.paragraph}>
                                    {currentText.Belongs.join(', ')}
                                </div>
                            </div>
                            <div className={styles.text}>
                                <div className={styles.title}>Nepatří sem</div>
                                <div className={styles.paragraph}>
                                    {currentText.NotBelongs.join(', ')}
                                    <br></br>
                                    {station?.properties.serviceName ===
                                        'VOK' && (
                                        <>
                                            Tyto složky lze odevzdat zdarma v
                                            jakémkoliv sběrném dvoře.
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {station?.properties?.containers && (
                    <div className={styles.containers}>
                        {station.properties.containers.map((container) => (
                            <ContainerAccordion
                                key={`container-accordion-${container.container_id}`}
                                container={container}
                            />
                        ))}
                        <div className={styles.spacer} />
                    </div>
                )}
                {mapType === 'waste-collection' ? <Tabs tabs={tabs} /> : <></>}
                {station?.properties.bulky_waste ? (
                    <div className={styles.bulkyStations}>
                        {station?.properties.bulky_waste
                            ?.slice()
                            .sort((a, b) => {
                                const dateA = new Date(
                                    `${a.properties.date}T${a.properties.timeFrom}`
                                )
                                const dateB = new Date(
                                    `${b.properties.date}T${b.properties.timeFrom}`
                                )

                                return dateA.getTime() - dateB.getTime()
                            })
                            .map((station) => {
                                const date = new Date(
                                    `${station.properties.date}T${station.properties.timeFrom}`
                                )
                                const dateCz = date.toLocaleDateString('cs-CZ')

                                const timeFrom = station.properties.timeFrom
                                const timeTo = station.properties.timeTo

                                const timeFromCz = formatTime(timeFrom)
                                const timeToCz = formatTime(timeTo)

                                return (
                                    <div
                                        className={styles.bulkyContainer}
                                        key={`station-${station.properties.customId}`}
                                    >
                                        <div className={styles.bulkyTitle}>
                                            Kontejner
                                        </div>
                                        <div className={styles.bulkyBox}>
                                            <div className={styles.bulkyItem}>
                                                <div
                                                    className={
                                                        styles.bulkyIconWrapper
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            styles.bulkyIcon
                                                        }
                                                        src={
                                                            '/img/icon/calendar.svg'
                                                        }
                                                        alt={''}
                                                    />
                                                </div>
                                                <div>
                                                    <strong>
                                                        Datum přistavení:{' '}
                                                    </strong>
                                                    {dateCz}
                                                </div>
                                            </div>
                                            <div className={styles.bulkyItem}>
                                                <div
                                                    className={
                                                        styles.bulkyIconWrapper
                                                    }
                                                >
                                                    <img
                                                        className={
                                                            styles.bulkyIcon
                                                        }
                                                        src={
                                                            '/img/icon/clock.svg'
                                                        }
                                                        alt={''}
                                                    />
                                                </div>
                                                <div>
                                                    <strong>
                                                        Doba přistavení:{' '}
                                                    </strong>
                                                    {timeFromCz}&nbsp;-&nbsp;
                                                    {timeToCz}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        <div className={styles.spacer} />
                    </div>
                ) : (
                    <></>
                )}
            </>
        </ResultWrapper>
    )
}
