import React, { useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { Footer } from './components/block/Footer/Footer'
import { Banner } from './components/block/Banner/Banner'
import { Spinner } from './components/block/Spinner/Spinner'
import { Map } from './components/page/Map'
import { Cookies } from './components/page/Cookies'

function App() {
    // handle displaying of spinner
    const [showSpinner, setShowSpinner] = useState(true)

    const location = useLocation()

    return (
        <div
            key={`app-root-${location.pathname}`}
            className={
                'fixed inset-0 w-screen h-screen flex flex-col max-h-full'
            }
        >
            <Banner />
            <div className={'relative flex-1'}>
                <Routes>
                    <Route
                        index={true}
                        path={'/'}
                        element={
                            <Map
                                type={'sorted-waste'}
                                setShowSpinner={setShowSpinner}
                            />
                        }
                    />
                    <Route
                        path={'/sberne-dvory'}
                        element={
                            <Map
                                type={'waste-collection'}
                                setShowSpinner={setShowSpinner}
                            />
                        }
                    />
                    <Route
                        path={'/vok'}
                        element={
                            <Map
                                type={'bulky-waste'}
                                setShowSpinner={setShowSpinner}
                            />
                        }
                    />
                    <Route
                        path={'/cookies'}
                        element={<Cookies setShowSpinner={setShowSpinner} />}
                    />
                </Routes>
                {showSpinner && <Spinner />}
            </div>
            <Footer />
        </div>
    )
}

export default App
